import { createContext, useContext, useMemo, useRef, useState } from 'react';

import deskpassApi from '@/api/deskpass';
import localServerApi from '@/api/localServer';

import { usePageVisibilityContext } from '@/context/PageVisibility';
import { useSessionContext } from '@/context/Session';
import { useThemeContext } from '@/context/Theme';

// import { identifyUser, initializeLogRocket } from '@/lib/logRocket';
import useEvent from '@/hooks/useEvent';
import { useMountAsync } from '@/hooks/useMount';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';
import useWatch from '@/hooks/useWatch';

import { consumerToHOC } from '@/lib/hoc';
import { sessionTrackerReady, setMetadata } from '@/lib/openReplay';
import { getNewRoomBookingMessages } from '@/lib/reservationHelpers';

const Context = createContext({});

// Export a dummy function and later on mutate it
export let logout = () => null;

export const initialState = {
  // Set to true when acknowledging the user is not logged or after
  // loading the current logged user.
  ready: false,
  // Set to true while the authentication process is happening
  authenticating: false,
  // Wether or not the user is logged in
  authenticated: false,
  // This will only be used during signup step while user
  // instance is not ready in the context
  invitedUserEmail: null,
  // This will only be used during signup step while user
  // instance is not ready in the context
  temporaryTeamOrganization: {},
  // When a team invite fails due to expiration or something else
  teamInviteError: false,
  // Logged user DB instance
  user: {},
  // TODO consider moving inside user on the /self API
  // User active Stripe coupon
  activeCoupon: {},
  // List of message updates tied to the
  // booking approval feature
  newRoomBookingMessages: [],
  // Flag to tell if the above is being loaded
  hourlyBookingMessageUpdatesLoading: false,
  // should show SignupLoginModal
  showSignupLoginModal: false,
  continueBookingAfterAuthSuccess: false,
};

export function UserProvider({ children, serverData: { userData } }) {
  const { visible } = usePageVisibilityContext();
  const { updateCustomization } = useThemeContext();
  const {
    retrieveAuthToken,
    clearSession,
    active: sessionActive,
  } = useSessionContext();

  const [state, setState] = useState({
    ...initialState,

    ...(userData && {
      ready: true,
      authenticated: userData.authenticated,
      user: userData.user,
      activeCoupon: userData.activeCoupon,
      newRoomBookingMessages: [
        ...userData.hourlyBookingMessageUpdates,
        ...userData.officeBookingMessageUpdates,
      ],
    }),
  });

  const pollerRef = useRef(null);

  const updateState = useStateMergeUpdater(setState);

  const { invitedUserEmail, user, temporaryTeamOrganization } = state;

  const teamUser = !!(invitedUserEmail ?? user?.isTeamUser);

  const teamOrganization = useMemo(
    () => user?.teamOrganization ?? temporaryTeamOrganization ?? {},
    [user, temporaryTeamOrganization],
  );

  const teamCustomization = useMemo(
    () => user?.teamOrganization?.customization ?? {},
    [user],
  );

  const loadRoomBookingMessageUpdates = useEvent(async () => {
    updateState({ hourlyBookingMessageUpdatesLoading: true });

    if (pollerRef.current) {
      clearTimeout(pollerRef.current);
    }

    const [hourlyBookingMessageUpdates, officeBookingMessageUpdates] =
      await Promise.all([
        deskpassApi.user.getHourlyBookingMessageUpdates(),
        deskpassApi.user.getOfficeBookingMessageUpdates(),
      ]);

    const newRoomBookingMessages = await getNewRoomBookingMessages([
      ...hourlyBookingMessageUpdates,
      ...officeBookingMessageUpdates,
    ]);

    updateState({
      newRoomBookingMessages,
      hourlyBookingMessageUpdatesLoading: false,
    });
  });

  const pollRoomMessageUpdates = useEvent(() => {
    const poller = setTimeout(() => {
      if (state.authenticated && visible) {
        loadRoomBookingMessageUpdates();
        pollRoomMessageUpdates();
      }
    }, 1000 * 60);

    pollerRef.current = poller;
  });

  const loadUser = useEvent(async () => {
    const [user, activeCoupon = {}] = await Promise.all([
      deskpassApi.user.getSelf(),
      deskpassApi.user.getActiveCoupon(),
      pollRoomMessageUpdates(),
    ]);

    if (user.teamOrganization) {
      updateCustomization(user?.teamOrganization?.customization ?? {});
    }

    updateState({ user, activeCoupon });

    return user;
  });

  const login = useEvent(async (email, password, dpCsrfToken) => {
    updateState({ authenticating: true });

    try {
      await localServerApi.auth.login(email, password, dpCsrfToken);
      await retrieveAuthToken();
      const user = await loadUser();

      sessionTrackerReady?.then(() => {
        setMetadata(user);
      });

      updateState({ authenticating: false, authenticated: true });
      closeSignupLoginModal();
      return user;
    } catch (err) {
      updateState({ authenticating: false, authenticated: false });
      throw err;
    }
  });

  const _logout = useEvent(async () => {
    await localServerApi.auth.logout();
    clearSession();
    updateState({
      user: {},
      activeCoupon: {},
      authenticated: false,
      ready: true,
    });
  });

  const openSignupLoginModal = useEvent(() => {
    if (!state.authenticated) updateState({ showSignupLoginModal: true });
  });

  const closeSignupLoginModal = useEvent(() => {
    updateState({ showSignupLoginModal: false });
  });

  useMountAsync(async () => {
    logout = _logout;

    if (sessionActive && visible) {
      pollRoomMessageUpdates();
    }

    if (!!userData) {
      return;
    }

    if (!sessionActive) {
      return updateState({ ready: true });
    }

    try {
      const user = await loadUser();

      sessionTrackerReady?.then(() => {
        setMetadata(user);
      });

      updateState({ authenticated: true, ready: true });
    } catch (err) {
      console.error('Error loading user', err);
      _logout();
    }
  });

  useWatch(
    (prevVisible) => {
      if (state.authenticated && !prevVisible && visible) {
        pollRoomMessageUpdates();
      }
    },
    [visible, state.authenticated],
    false, // Skip mount
  );

  const updateUser = useEvent(async (userPayload, noReload = false) => {
    const result = await deskpassApi.user.updateSelf(userPayload);

    if (noReload) return result;

    return await loadUser();
  });

  const loadTeamInvitation = useEvent(async (hash) => {
    try {
      const { email, firstName, lastName, teamOrganization } =
        await deskpassApi.user.getTeamInvitation(hash);

      updateState({
        invitedUserFirstName: firstName,
        invitedUserLastName: lastName,
        invitedUserEmail: email,
        temporaryTeamOrganization: teamOrganization,
      });

      return { email, firstName, lastName, teamOrganization };
    } catch (err) {
      updateState({ teamInviteError: true });
    }
  });

  /**
   * Signifies to proceed to confirm modal after successful authentication.
   * Used before and after signup/login prompt during a booking.
   */
  const setContinueBookingAfterAuthSuccess = useEvent((status) => {
    updateState({ continueBookingAfterAuthSuccess: status });
  });

  const context = useMemo(
    () => ({
      ...state,
      updateUser,
      loadUser,
      login,
      logout: _logout,
      teamUser,
      teamOrganization,
      teamCustomization,
      loadTeamInvitation,
      openSignupLoginModal,
      closeSignupLoginModal,
      setContinueBookingAfterAuthSuccess,
    }),
    [
      state,
      updateUser,
      loadUser,
      login,
      _logout,
      teamUser,
      teamOrganization,
      teamCustomization,
      loadTeamInvitation,
      openSignupLoginModal,
      closeSignupLoginModal,
      setContinueBookingAfterAuthSuccess,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const withUserContext = consumerToHOC(Context.Consumer, 'userContext');
export const useUserContext = () => useContext(Context);
export default Context;
