import React from 'react';

import { setAuthToken } from '@/api/deskpass/network-client';
import localServerApi from '@/api/localServer';

import LoadingContent from '@/components/helpers/LoadingContent';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

let afterFirstRender = false;

export function SessionProvider({ children, serverData }) {
  if (serverData.session?.accessToken && !afterFirstRender) {
    setAuthToken(serverData.session.accessToken);
    afterFirstRender = true;
  }

  // If session loaded really fast initialize it synchronously
  const [session, setSession] = React.useState(() => ({
    ready: !!serverData.session,
    loading: false,
    ...(serverData.session && {
      active: serverData.session.active,
    }),
  }));

  const updateSession = useStateMergeUpdater(setSession);

  const retrieveAuthToken = useEvent(async () => {
    updateSession({ loading: true });
    const active = await localServerApi.auth.retrieveAuthToken();
    updateSession({ ready: true, loading: false, active });
  });

  // TODO we should revisit this eventually
  // since the value comes from the server.
  useMount(() => {
    if (session.ready) return;

    retrieveAuthToken();
  });

  const clearSession = useEvent(() => updateSession({ active: false }));

  const context = React.useMemo(
    () => ({
      ...session,
      retrieveAuthToken,
      clearSession,
    }),
    [session, retrieveAuthToken, clearSession],
  );

  return (
    <Context.Provider value={context}>
      {session.ready ? <>{children}</> : <LoadingContent />}
    </Context.Provider>
  );
}

export const withSessionContext = consumerToHOC(
  Context.Consumer,
  'sessionContext',
);
export const useSessionContext = () => React.useContext(Context);
export default Context;
