import { useEffect, useRef } from 'react';

import useEvent from '@/hooks/useEvent';

import useMount from './useMount';

export default function useWatch(
  effectCallback = () => null,
  watchedValues = [],
  fireOnMount = true,
) {
  if (!Array.isArray(watchedValues)) {
    throw new Error('"useWatch" second argument must be an array.');
  }

  const instance = useRef({
    fireOnMount,
    mounted: false,
  });

  const previousRef = useRef(watchedValues);

  const eventCallback = useEvent(effectCallback);
  const dependencies = watchedValues.concat(eventCallback);

  useEffect(
    () => {
      const { fireOnMount, mounted } = instance.current;

      if (!fireOnMount && !mounted) return;

      const disposer = eventCallback(...previousRef.current);
      previousRef.current = watchedValues;

      return disposer;
    },
    // eslint-disable-next-line
    dependencies,
  );

  // This needs to be set after the effect so mounted is only true on
  // the second invocation and forward.
  useMount(() => {
    instance.current.mounted = true;
  });
}
