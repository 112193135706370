import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useGlobalConfigContext } from '@/context/GlobalConfig';

import useEvent from '@/hooks/useEvent';

import { consumerToHOC } from '@/lib/hoc';
import { slugifyCamelcase } from '@/lib/stringHelpers';

import getAppColors, { defaultColors } from '@/styles/js/colors';
import CSSVariables from '@/styles/js/global/cssVariables';

const Context = createContext({});
const { Consumer, Provider } = Context;

export function ThemeProvider({ children }) {
  const { globalConfig } = useGlobalConfigContext() ?? {};
  const { customization: globalCustomization = {} } = globalConfig ?? {};

  const [customization, setCustomization] = useState(() => globalCustomization);

  const updateCustomization = useEvent((teamCustomization) =>
    setCustomization({
      ...globalCustomization,
      ...teamCustomization,
    }),
  );

  // Current customization colors
  const colors = useMemo(() => {
    let customColors = {};
    if (Object.keys(customization).length) {
      const { primaryColor, secondaryColor } = customization;

      if (primaryColor) customColors.primary = primaryColor;
      // If the team secondary color is white, we use the default accent color
      // to avoid white accent on white background issues
      if (
        secondaryColor &&
        ['#fff', '#ffffff', 'white'].includes(
          String(secondaryColor).toLowerCase(),
        )
      ) {
        customColors.accent = defaultColors.accent;
      } else if (secondaryColor) {
        customColors.accent = secondaryColor;
      }
    }

    return getAppColors(customColors);
  }, [customization]);

  // Updates the :root CSS variables with the new theme colors
  const updateTheme = useCallback(() => {
    const cssRootScope = document.querySelector(':root').style;

    // Sets theme colors on css root scope
    Object.keys(colors).forEach((colorPropName) => {
      const cssVariableName = `--${slugifyCamelcase(colorPropName)}-color`;
      cssRootScope.setProperty(cssVariableName, colors[colorPropName]);
    });
  }, [colors]);

  // Updates theme whenever customization colors change
  useEffect(() => {
    updateTheme();
  }, [updateTheme]);

  // Final context value
  const context = useMemo(() => {
    const customized = !!Object.keys(customization).length;

    let values = {
      simplifiedLogo: '',
      companyLogo: '',
      colors,
      customized,
      updateCustomization,
    };

    if (customized) {
      values.simplifiedLogo = customization.simplifiedLogo;
      values.companyLogo = customization.companyLogo;
    }

    return values;
  }, [colors, customization, updateCustomization]);

  const styledTheme = useMemo(() => ({ colors }), [colors]);

  // TODO Remove CSS variables when we're done with the CSS in JS conversion
  return (
    <StyledThemeProvider theme={styledTheme}>
      <CSSVariables />

      <Provider value={context}>{children}</Provider>
    </StyledThemeProvider>
  );
}

export const useThemeContext = () => React.useContext(Context);
export const ThemeConsumer = Consumer;
export const withThemeContext = consumerToHOC(Consumer, 'themeContext');
export default Context;
