import haversine from 'haversine';
import moment from 'moment-timezone';

export const getAvailabilityOnDate = (space, date) => {
  let { close, open, blackoutInterval } = getDayOpenClose(space, date);

  function makeAvailabilityEntry(overrideProps = {}) {
    return {
      availability: [],
      closeEarly: false,
      openLate: false,
      closeAndReopen: false,
      ...overrideProps,
    };
  }

  // If this happens we're closed
  if (!open || !close) {
    return makeAvailabilityEntry();
  }

  close = moment.tz(close.format(), space.timezone);
  open = moment.tz(open.format(), space.timezone);

  if (blackoutInterval) {
    const { start: blackoutClose, end: blackoutOpen } = blackoutInterval;

    // If blackout time starts and end in the same time as the weekeday open close times
    // we can consider the space closed for the day. So we just return closed here;
    if (blackoutClose <= open && blackoutOpen >= close) {
      return makeAvailabilityEntry();
    }

    // When this happens means they are opening later then usual on the given date
    if (blackoutClose <= open && blackoutOpen < close) {
      return makeAvailabilityEntry({
        availability: [blackoutOpen, close],
        openLate: true,
      });
    }

    // When this happens means they are closing earlier then usual on the given date
    if (blackoutClose > open && blackoutOpen >= close) {
      return makeAvailabilityEntry({
        availability: [open, blackoutClose],
        closeEarly: true,
      });
    }

    // When this happens means they will close for a while during the day
    // But they'll open and close on their regular schedule
    if (blackoutClose > open && blackoutOpen < close) {
      return makeAvailabilityEntry({
        availability: [open, blackoutClose, blackoutOpen, close],
        closeAndReopen: true,
      });
    }
  }

  return makeAvailabilityEntry({
    availability: [open, close],
  });
};

/**
 * Given a space and a moment instance, creates a string
 * telling how many hours before the space closes.
 *
 * @param {Object} space  Space DB model
 * @param {Moment} date   moment instance
 *
 * @return {String} returns formatterd text with how long before close
 */
export const getOpenCloseTimeString = (space, date) => {
  const now = moment.tz(space.timezone);
  let hoursBeforeCloseMsg = null;

  if (now.isSame(date, 'day')) {
    const { availability } = getAvailabilityOnDate(space, now);
    const closeTime = availability[availability.length - 1];

    // THis means it's closed
    if (now.isAfter(closeTime) || availability.length === 0) {
      return null;
    }

    // The + 1 is cause we're always rounding the hours up.
    const hoursBeforeClose = closeTime.diff(now, 'hours') + 1;

    // Starts messaging hours before close when at least 4 hours remain
    if (hoursBeforeClose <= 4) {
      if (hoursBeforeClose > 1) {
        hoursBeforeCloseMsg = `Closes in ${hoursBeforeClose} hours.`;
      } else {
        hoursBeforeCloseMsg = `Closes in less than an hour.`;
      }
    }
  } else {
    const { availability, ...info } = getAvailabilityOnDate(space, date);

    if (info.openLate) {
      const [open] = availability;
      hoursBeforeCloseMsg = `Space will open at ${open.format(
        'h:mma',
      )} on ${open.format('ddd, MMM D')}`;
    }

    if (info.closeEarly) {
      const close = availability[1];
      hoursBeforeCloseMsg = `Space will close at ${close.format(
        'h:mma',
      )} on ${close.format('ddd, MMM D')}`;
    }

    if (info.closeAndReopen) {
      const [close, open] = availability.slice(1, 3);
      hoursBeforeCloseMsg = `Space will be closed between ${close.format(
        'h:mma',
      )} and ${open.format('h:mma')} on ${close.format('ddd, MMM D')}`;
    }
  }

  return hoursBeforeCloseMsg;
};

/**
 * Given a space instance, render space's hours for the current week. Assumes
 * weekday_intervals are populated in the space instance.
 *
 * @param {Object} weekdayHours Array of weekday hours to format
 * @param {Date} baseDate Date which determines which week will be used to
 * render hours.  If not passed, current day and week will be used.
 *
 * @return {Array} Returns array containing day label, open time and close
 * time
 */
export const renderSpaceHours = (weekdayHours, baseDate) => {
  if (!weekdayHours.length) {
    return [];
  }

  // Set base date used to figure out what week we're in - this will just
  // default to current date if baseDate is not passed
  baseDate = moment(baseDate);

  // Key hours by weekday index for easy reference
  const hoursKeyed = weekdayHours.reduce((current, next) => {
    current[next.weekday] = next;

    return current;
  }, {});

  // Format hours for every weekday as well as current day if possible
  let formatted = [];

  // Attempt to format hours for current day to start
  let today = hoursKeyed[moment(baseDate).day()];

  function parseTime(time, weekday) {
    return moment(time, 'HH:mm')
      .set({
        date: baseDate.date(),
        month: baseDate.month(),
        year: baseDate.year(),
      })
      .day(weekday)
      .format('h:mma');
  }

  if (today && !!today.availability) {
    formatted.push({
      label: 'Today',
      closed: today.availability.length === 0,
      availability: today.availability.map((it) => ({
        from: parseTime(it.from, today.weekday),
        to: parseTime(it.to, today.weekday),
      })),
    });
  } else {
    formatted.push({
      label: 'Today',
      closed: true,
      availability: [],
    });
  }

  // Page through hours, formatting as we go
  formatted = formatted.concat(
    moment.weekdays().map((weekday, index) => {
      const day = hoursKeyed[index];
      if (!!day && !!day.availability) {
        return {
          label: moment(baseDate).day(day.weekday).format('ddd, MMM D'),
          closed: day.availability.length === 0,
          availability: day.availability.map((it) => ({
            from: parseTime(it.from, day.weekday),
            to: parseTime(it.to, day.weekday),
          })),
        };
      } else {
        return {
          label: moment(baseDate).day(index).format('ddd, MMM D'),
          closed: true,
          availability: [],
        };
      }
    }),
  );

  // Shoot back formatted availability
  return formatted;
};

/**
 * Given a space, return open/close times for the space on the specified day.
 *
 * @param {Space} space Space to check open status for
 * @param {Date} date Date to check availability for - current day will be
 * used if not passed [optional]
 * @param {Date} now Current time to check availability for - current time
 * will be used if not passed [optional]
 *
 * @return {Object} Returns object containing open/close Moments for the passed
 * space and day
 */
export const getDayOpenClose = (space, date) => {
  if (!date) {
    throw new Error('Date must be passed to getDayOpenClose');
  }

  date = moment.tz(date, space.timezone).startOf('day');

  // If space doesn't have any weekday availability it's probably not open
  if (!space.weekdayHours.length) {
    return false;
  }

  const blackoutDate = (space.closedDays || [])
    // Have a blackout date on the given date
    .find((it) => date.isSame(moment.tz(it.start, space.timezone), 'day'));

  // If it's a blackout date all day then the space is closed.
  if (blackoutDate && blackoutDate.allDay) {
    return false;
  }

  // Attempt to find matching weekday for today
  const weekdays = space.weekdayHours.reduce((current, next) => {
    current[next.weekday] = next;

    return current;
  }, {});

  let currentWeekday = weekdays[date.weekday()];

  // If we can't find matching weekday in space's weekday hours, it's not
  // available
  if (!currentWeekday) {
    return false;
  }

  // Now figure out if space is actually open right now
  const start = currentWeekday.start.split(':');
  const end = currentWeekday.end.split(':');

  let open = moment(date).set({ hours: start[0], minutes: start[1] });
  let close = moment(date).set({ hours: end[0], minutes: end[1] });

  let availability = { open, close };

  if (blackoutDate) {
    availability.blackoutInterval = {
      start: moment.tz(blackoutDate.start, space.timezone),
      end: moment.tz(blackoutDate.end, space.timezone),
    };
  }

  return availability;
};

/**
 * TODO at some point refactor this to use "getAvailabilityOnDate" instead of these messy IF's
 *
 * Given a space, return open/close times for the space on the specified day,
 * formatted for display
 *
 * @param    {Space}   space   Space to check open status for
 * @param    {Date}    date    Date to check availability for [optional]
 * @return   {String}  The formatted open/close string
 */
export const getOpenCloseHoursString = (space, date, options = {}) => {
  const { open, close, blackoutInterval } = getDayOpenClose(space, date);
  let hours = `Closed on ${date.format('MMM Do')}`;

  if (date.isSame(moment(), 'day')) {
    hours = `Closed Today`;
  }
  if (date.isSame(moment().add(1, 'day'), 'day')) {
    hours = `Closed Tomorrow`;
  }
  const closed = hours;

  if (open && close) {
    const regularOpenHour = open.format('h:mma');
    const regularCloseHour = close.format('h:mma');
    hours = `${regularOpenHour}–${regularCloseHour}`;

    if (blackoutInterval) {
      const now = moment.tz(space.timezone);
      const { start: blackoutClose, end: blackoutOpen } = blackoutInterval;

      const blackoutCloseHour = blackoutClose.format('h:mma');
      const blackoutOpenHour = blackoutOpen.format('h:mma');

      // If blackout time starts and end in the same time as the weekeday open close times
      // we can consider the space closed for the day. So we just return closed here;
      if (blackoutClose <= open && blackoutOpen >= close) {
        return closed;
      }

      // When this happens means they are opening later then usual on the given date
      if (blackoutClose <= open && blackoutOpen < close) {
        if (now.isBetween(blackoutOpen, close)) {
          // Show as available if possible
          hours = `${blackoutOpenHour}–${regularCloseHour}`;
        } else {
          // If the space is already closed now just shoot this back
          return `Opens today at ${blackoutOpenHour}`;
        }
      }

      // When this happens means they are closing earlier then usual on the given date
      if (blackoutClose > open && blackoutOpen >= close) {
        if (now.isBetween(open, blackoutClose)) {
          // Show as available if possible
          hours = `${regularOpenHour}–${blackoutCloseHour}`;
        } else {
          // If the space is already closed now just shoot this back
          return `Closed today`;
        }
      }

      // When this happens means they will close for a while during the day
      // But they'll open and close on their regular schedule
      if (blackoutClose > open && blackoutOpen < close) {
        if (now.isBetween(blackoutCloseHour, blackoutOpenHour)) {
          // Show as available if possible
          hours = `${regularOpenHour}–${blackoutCloseHour}, ${blackoutOpenHour}–${regularCloseHour}`;
        } else {
          // If the space is already closed now just shoot this back
          return `Opens today at ${blackoutOpenHour}`;
        }
      }
    }

    if (options.showWeekdayIndicator) {
      if (open.isSame(moment(), 'day')) {
        hours = `Today ${hours}`;
      } else if (open.isSame(moment().add(1, 'day'), 'day')) {
        hours = `Tomorrow ${hours}`;
      } else {
        hours = `${open.format('ddd')} ${hours}`;
      }
    }
  }

  return hours;
};

/**
 * Calculate distance between current user location and specified space or
 * room. User location must be set for this to work.
 *
 * @param {Mixed} spaceOrRoom Space or Room to calculate distance to.  Must
 * have lat/lng set in Space to calculate distance if Space is passed or Space
 * set in Room if Room is passed
 *
 * @return {Float} Returns distance from user to space/room, in miles
 */
export const distanceToSpaceOrRoom = (spaceOrRoom = {}, lngLat = {}) => {
  const space =
    spaceOrRoom.modelName === 'Room' ? spaceOrRoom.space : spaceOrRoom;
  const { lng, lat } = lngLat;

  if (!space) {
    return console.info(
      'Unable to calculate distance to space: space not passed',
    );
  }

  if (!lng || !lat) {
    return console.info(
      'Unable to calculate distance to space: longitude/latitude not set',
    );
  }

  if (!space.lng || !space.lat) {
    return console.info(
      'Unable to calculate distance to space: space lat/lng not set',
    );
  }

  return haversine(
    { latitude: space.lat, longitude: space.lng },
    { latitude: lat, longitude: lng },
    { unit: 'mile' },
  );
};

/**
 * Calculate distance between 2 locations.
 *
 * @param {LatLng} from       object containing lat and lng props
 * @param {LatLng} to         object containing lat and lng props
 *
 * @return {Float} Returns distance from user to space/room, in miles
 */
export const distanceTo = (from = {}, to = {}) => {
  if (!from.lng || !from.lat || !to.lng || !to.lat) {
    return console.info(
      'Unable to calculate distance: "from" and "to" needs to have lat and lng',
    );
  }

  return haversine(
    { latitude: from.lat, longitude: from.lng },
    { latitude: to.lat, longitude: to.lng },
    { unit: 'mile' },
  );
};

export const getOpenCloseTimes = (weekdayHours, dateBooked) => {
  const weekdaySlug = moment.parseZone(dateBooked).format('dddd').toLowerCase();
  const weekday = weekdayHours.find((day) => day.weekdaySlug === weekdaySlug);

  if (!weekday) {
    return null;
  }

  return {
    openTime: moment(weekday.start, 'hh:mm').format('h:mma'),
    closeTime: moment(weekday.end, 'hh:mm').format('h:mma'),
  };
};
