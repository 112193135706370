import moment from 'moment-timezone';

import { formatCurrency } from '@/lib/currencyHelpers';
import { diffAmountHours } from '@/lib/dateHelpers';
import { getOpenCloseTimes } from '@/lib/spaceHelpers';

/**
 * Helper functions related to billing functionality.
 */

/**
 * Adjust passed amount by passed Stripe coupon, returning the adjusted amount.
 * If the coupon isn't valid or another issue occurs, will just return the
 * original amount.
 *
 * @param {Object} coupon Stripe Coupon object describing discount to apply to
 * amount
 * @param {Float} amount Amount to adjust with coupon (as a decimal value, NOT
 * in cents)
 *
 * @return {Float} Returns amount adjusted by coupon if possible
 */
export const applyCoupon = (coupon, amount) => {
  // Nothing to apply so just return the original amount. Good job everyone!
  if (!coupon) {
    return amount;
  }

  try {
    if (!coupon.valid) {
      throw Error('invalidCoupon');
    }

    if (coupon.percent_off) {
      amount = amount * ((100 - coupon.percent_off) * 0.01);
    } else if (coupon.amount_off) {
      amount = Math.max(amount - coupon.amount_off * 0.01, 0);
    }

    return +Math.max(amount, 0).toFixed(2);
  } catch (err) {
    console.error(`Error applying coupon ${coupon?.id}`, err);

    return amount;
  }
};

/**
 * Calculate the remaining balance for a reservation based on the total cost of
 * the reservation, any discounts applied to the reservation, and the current
 * balance remaining for the (team) user.
 *
 * @param {Object} reservation - The reservation object.
 * @param {number} total - The total amount.
 * @param {Array<Object>} discountLines - An array of discount line objects.
 * @param {number} convertCurrencyRate - The currency conversion rate.
 * @param {number} currentMonthBalanceRemaining - The remaining balance for the current month.
 * @param {Object} teamOrganization - The team organization object.
 * @param {Object} room - The room object.
 * @returns {Object} Object containing the `balanceValue`, `discountedTotal`, `total`, `isBalanceInsufficient`.
 */
export const calculateRemainingBalance = (
  reservation,
  total,
  discountLines,
  convertCurrencyRate,
  currentMonthBalanceRemaining,
  teamOrganization,
  room,
) => {
  const isDaily = reservation.modelName === 'Booking';

  let balanceValue = '';
  let discountedTotal = 0;

  // If currency conversion rate was passed, run the total through that before
  // displaying anything
  if (convertCurrencyRate && convertCurrencyRate !== 1) {
    total *= convertCurrencyRate;
  }

  // Pull currency symbol from the user's team since their billing will fall
  // under the team's billing
  const currencySymbol = teamOrganization?.country?.currencySymbol;

  // If we're displaying total for an existing booking, just display the current
  // remaining balance, because the booking will have already been paid for so
  // there's no need to subtract the total for the booking from the displayed
  // balance
  if (!reservation?.id) {
    if (discountLines?.length) {
      const discountedSum = discountLines.reduce((acc, it) => {
        let discount = it.discount;

        if (convertCurrencyRate && convertCurrencyRate !== 1) {
          discount *= convertCurrencyRate;
        }

        acc += discount;

        return acc;
      }, 0);
      discountedTotal = Math.max(0, total - discountedSum);
    } else {
      discountedTotal = total;
    }
  }

  if (isDaily) {
    balanceValue = formatCurrency(
      currentMonthBalanceRemaining - discountedTotal,
      currencySymbol,
    );
  }

  if (!isDaily && !room?.isOffice) {
    balanceValue = formatCurrency(
      currentMonthBalanceRemaining - discountedTotal,
      currencySymbol,
    );
  }

  if (!isDaily && room?.isOffice) {
    balanceValue = formatCurrency(
      currentMonthBalanceRemaining - discountedTotal,
      currencySymbol,
    );
  }

  const isBalanceInsufficient = Boolean(
    currentMonthBalanceRemaining - discountedTotal < 0,
  );

  return { balanceValue, discountedTotal, total, isBalanceInsufficient };
};

/**
 * Calculates the total cost including discounts
 * applied to the **Hourly or Office** reservation.
 *
 * @param {Object} reservation - The reservation object.
 * @param {boolean} [extendReservation] - Whether the reservation is being extended.
 * @param {Object} user - The user object.
 * @param {Object} room - The room object.
 * @param {Object} [activeCoupon] - The active coupon object.
 * @param {Object} billingFigures - The billing figures `total`, `transactionFee`.
 * @returns {Object} An object containing the `total`, `description`, `discountLines`, and include `showDayRate` for hourly booking.
 */
export const calculateRoomReservationTotal = (
  reservation,
  extendReservation,
  user,
  room,
  activeCoupon,
  billingFigures,
) => {
  let { hourlyRate, isOffice, transactionFeePercentage } = room;

  let total = 0,
    showDayRate = null,
    description = '';

  if (isOffice) {
    total = billingFigures?.total ?? 0;
  } else {
    // format date if its an instance of moment
    // otherwise, just pass the date as is to diffAmountHours
    const start = moment.isMoment(reservation?.start)
      ? reservation.start.format()
      : reservation.start;
    const end = moment.isMoment(reservation?.end)
      ? reservation.end.format()
      : reservation.end;

    // Figure out correct day rate to use
    const dayRate = isOffice ? room.officeDayRate : room.dayRate;
    const hours = diffAmountHours(
      extendReservation ? reservation.endOriginal : start,
      end,
    );

    showDayRate = Boolean(dayRate) && hours >= 8;
    total = showDayRate ? dayRate || 0 : hours * hourlyRate;

    description = showDayRate
      ? `${formatCurrency(dayRate || 0, reservation.currencySymbol)}/day`
      : `${formatCurrency(
          hourlyRate,
          reservation.currencySymbol,
        )}/hour × ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  // Then apply discounts
  let discountLines = [];

  // If room/space is private, it won't cost anything, ever. EVER.
  if (room.isPrivate || reservation.space.isPrivate) {
    // HQ bookings are free
    total = 0;
    description = 'HQ Reservation';
  } else {
    // Tack on transaction fee before applying any discounts--transactionFee
    // will be around if the fee has already been calculated for an existing
    // reservation, transactionFeePercentage will be present on the space if
    // user is creating a new reservation
    let transactionFee = billingFigures?.transactionFee ?? 0;

    if (transactionFee) {
      discountLines.push({
        discount: -transactionFee,
        description: 'Service Fee',
      });
    } else if (transactionFeePercentage) {
      transactionFee = (transactionFeePercentage / 100) * total;

      discountLines.push({
        discount: -transactionFee,
        description: `${transactionFeePercentage}% Service Fee`,
      });
    }

    // Figure out any discounts to the total, if applicable
    let adjustedTotal = total + transactionFee;

    // If user is a teams user or non-subscription user and has coupon active,
    // apply coupon as discount amount
    if (total && activeCoupon?.id) {
      const originalTotal = adjustedTotal;
      adjustedTotal = applyCoupon(activeCoupon, adjustedTotal);

      discountLines.push({
        discount: originalTotal - adjustedTotal,
        description: `${activeCoupon.id} coupon`,
      });
    }

    // If user has a positive Deskpass account balance,
    // apply that to remaining
    // total
    const accountBalance = reservation.id
      ? user.accountBalancePending
      : user.accountBalance;

    if (accountBalance) {
      discountLines.push({
        description: 'Free Credit',
        discount: Math.min(accountBalance, adjustedTotal),
      });
    }
  }

  return { total, description, discountLines, showDayRate };
};

/**
 * Calculates the total cost including discounts
 * applied to the **Deskpass** reservation.
 *
 * @param {Object} reservation - The reservation object.
 * @param {Object} user - The user object.
 * @param {Object} activeCoupon - The active coupon object.
 * @returns {Object} An object containing the `total`, `description`, `observation`, and `discountLines`.
 */
export const calculateDeskReservationTotal = (
  reservation,
  user,
  activeCoupon,
) => {
  let { dateBooked, space, transactionFee } = reservation;
  const { timezone, bookingCost, weekdayHours, transactionFeePercentage } =
    space;

  const numberOfGuests = (reservation?.guests || []).filter(
    (guest) => guest.status !== 'cancelled',
  ).length;
  const attendees = numberOfGuests + 1;

  // Put together open/close string for reservation date
  let observation = '';

  const openCloseTimes = getOpenCloseTimes(
    weekdayHours,
    moment.tz(dateBooked, timezone),
  );

  if (openCloseTimes) {
    observation = `${openCloseTimes.openTime} to ${openCloseTimes.closeTime}`;
  }

  // Use the reservation billingFigures total for reserved booking,
  // otherwise it's pre-reserved so use the space bookingCost
  let total = (reservation?.billingFigures?.total ?? bookingCost) * attendees;
  let description = `1 day pass/visit x ${attendees} attendee${
    attendees > 1 ? 's' : ''
  }`;

  // Then apply discounts
  let discountLines = [];

  if (reservation.space.isPrivate) {
    // HQ bookings are free
    total = 0;
    description = 'HQ Reservation';
  }

  // Tack on transaction fee before applying any discounts--transactionFee
  // will be around if the fee has already been calculated for an existing
  // reservation, transactionFeePercentage will be present on the space if
  // user is creating a new reservation
  if (transactionFee) {
    discountLines.push({
      discount: -(transactionFee * attendees),
      description: 'Service Fee',
    });
  } else if (transactionFeePercentage) {
    transactionFee = (transactionFeePercentage / 100) * total;

    discountLines.push({
      discount: -transactionFee,
      description: `${transactionFeePercentage}% Service Fee`,
    });
  } else {
    transactionFee = 0;
  }

  // Figure out any discounts to the total, if applicable
  let adjustedTotal = total + transactionFee;

  // If user is a teams user or non-subscription user and has coupon active,
  // apply coupon as discount amount
  if (total && activeCoupon?.id) {
    const originalTotal = adjustedTotal;
    adjustedTotal = applyCoupon(activeCoupon, adjustedTotal);

    discountLines.push({
      discount: originalTotal - adjustedTotal,
      description: `${activeCoupon.id} coupon`,
    });
  }

  // If user has a positive Deskpass account balance, apply that to remaining
  // total
  // Check if the reservation has been saved or not, because if it has the
  // user's balance will have been moved to pending balance
  const accountBalance =
    (reservation.id ? user?.accountBalancePending : user?.accountBalance) || 0;

  if (accountBalance) {
    discountLines.push({
      description: 'Free Credit',
      discount: Math.min(accountBalance, adjustedTotal),
    });
  }

  return { total, description, discountLines, observation };
};
