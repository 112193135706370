import { removeAuthToken, setAuthToken } from '@/api/deskpass/network-client';

import localServerApiInstance from './client';

export function createClient(instance = localServerApiInstance) {
  return {
    auth: {
      login(email, password, dpCsrfToken) {
        return instance.post('/auth/login', {
          username: email,
          password,
          dpCsrfToken,
        });
      },

      async logout() {
        await instance.post('/auth/logout');

        removeAuthToken();

        return true;
      },

      async retrieveAuthToken() {
        const { result: authToken } = await instance.get('/auth/token');

        setAuthToken(authToken);

        return !!authToken;
      },

      async retrieveDPCSRFToken() {
        return instance.get('/auth/csrf');
      },
    },

    global: {
      logError({ message, cause, stack, componentStack }) {
        return instance.post('/error-log', {
          message,
          cause,
          stack,
          componentStack,
        });
      },
    },
  };
}

export default createClient();
