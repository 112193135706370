import localForage from 'localforage';
import moment from 'moment-timezone';

import { applyCoupon } from '@/lib/billingHelpers';
import { keyBy } from '@/lib/utility';

/**
 * Given a room and start/end interval, calculate total cost for the
 * reservation.
 * @TODO: Add guests into total calculations
 * @TODO: Day rate calculations
 *
 * @param {Room}          room        Room to calculate total under
 * @param {Date}          start       Start datetime for reservation
 * @param {Date}          end         End datetime for reservation
 *
 * @return {Float} Returns calculated cost of the reservation
 */
export function hourlyReservationTotal(room, start, end) {
  start = moment(start);
  end = moment(end);

  return (end.diff(start, 'minutes') / 60) * room.hourlyRate;
}

/**
 * Mark all messages under passed reservation as being viewed.
 *
 * @param {Integer} bookingId ID of booking messages to mark as viewed
 *
 * @return {Promise} Returns after updating messages
 */
export function markMessageAsViewed(bookingId) {
  // Pull current roomBookingMessageStatus object
  return localForage
    .getItem('roomBookingMessageStatus')
    .then((messageStatus) => {
      // If messages doesn't exist yet just create a blank object (will be
      // populated as we go)
      if (!messageStatus) {
        messageStatus = {};
      }

      if (messageStatus[bookingId]) {
        // Modify existing entry if it already exists (right now these two
        // cases are the same but might not be if we add more keys to the
        // object)
        messageStatus[bookingId].lastViewed = moment().format();
      } else {
        // If the booking doesn't exist in status object yet, create it
        messageStatus[bookingId] = { lastViewed: moment().format() };
      }

      // And save out the result
      return localForage.setItem('roomBookingMessageStatus', messageStatus);
    });
}

/**
 * Check if user's hourly/office bookings have any that have an updated message.
 * This will check the message hash contained in the booking against the
 * cache of message hashes stored on the user's end to figure out which
 * messages are new to make sure we're not bugging the user with
 * notifications about new messages repeatedly.
 *
 * @param {Object} messages Object containing room booking message updates for user
 *
 * @return {Promise} Returns array of new messages
 */
export function getNewRoomBookingMessages(messages) {
  // // Kill off current message list when testing
  // localForage.removeItem('roomBookingMessageStatus');

  // Pull the local cache of booking message hashes (this is how we determine
  // if a user has seen a particular message or not)
  return localForage
    .getItem('roomBookingMessageStatus')
    .then((messageStatus) => {
      // If messages doesn't exist yet just create a blank object (will be
      // populated as we go)
      if (!messageStatus) {
        messageStatus = {};
      }

      // Key messages by booking ID for easy reference
      const messagesById = keyBy(messages, 'id');

      // Flip through current messages comparing the status of each with the
      // stored record for the message on our end and figuring out which
      // messages have changed
      let withNewMessages = [];

      for (let message of messages) {
        // If there's no record of the message at all,
        // create blank object for it
        if (!messageStatus[message.id]) {
          messageStatus[message.id] = { lastViewed: null };
        }

        if (
          // Make sure status has actually changed at all
          !!message.statusUpdated &&
          // If the status update date is newer than the last time the message
          // was viewed, it's also new
          (!messageStatus[message.id].lastViewed ||
            moment(message.statusUpdated).isAfter(
              moment(messageStatus[message.id].lastViewed),
            ))
        ) {
          withNewMessages.push(message);
        }
      }

      // One last thing while we're here, if a message isn't around in list
      // of currentBookings, just delete it from the messageStatus list
      for (let id of Object.keys(messageStatus)) {
        if (!messagesById[id]) {
          delete messageStatus[id];
        }
      }

      // Sort by upcoming date so it's easy to find the soonest message that
      // will be happening
      withNewMessages.sort((a, b) =>
        moment(a.start) >= moment(b.start) ? 1 : -1,
      );

      // Save out the updated messageStatus object
      localForage.setItem('roomBookingMessageStatus', messageStatus);

      // Shoot back compiled array of messages with new messages
      return Promise.resolve(withNewMessages);
    });
}

/**
 * calcOfficeTotalDiscount
 * ---
 * Calculate the total discount applied to an office
 * reservation including any service fee and returns
 * the calculated discount.
 */
export const calcOfficeTotalDiscount = ({
  reservation,
  activeCoupon,
  transactionFeePercentage,
  billingFigures,
  user,
}) => {
  let { total } = billingFigures;

  // Applied discounts
  let calculatedDiscount = 0;

  // Tack on transaction fee before applying any discounts--transactionFee
  // will be around if the fee has already been calculated for an existing
  // reservation, transactionFeePercentage will be present on the space if
  // user is creating a new reservation
  let transactionFee = billingFigures?.transactionFee ?? 0;

  if (transactionFee) {
    // Service Fee
    calculatedDiscount += -transactionFee;
  } else if (transactionFeePercentage) {
    transactionFee = (transactionFeePercentage / 100) * total;
    // `${transactionFeePercentage}% Service Fee`
    calculatedDiscount += -transactionFee;
  }

  // Figure out any discounts to the total, if applicable
  let adjustedTotal = total + transactionFee;

  // If user is a teams user or non-subscription user and has coupon active,
  // apply coupon as discount amount
  if (total && activeCoupon?.id) {
    const originalTotal = adjustedTotal;

    adjustedTotal = applyCoupon(activeCoupon, adjustedTotal);

    // `${activeCoupon.id} coupon`
    calculatedDiscount += originalTotal - adjustedTotal;
  }

  // Then subtract Deskpass balance from the final adjusted balance if
  // applicable
  if (
    (!reservation.id && user.accountBalance) ||
    (reservation.id && user.accountBalancePending)
  ) {
    // 'Free Credit'
    calculatedDiscount += Math.min(
      reservation.id ? user.accountBalancePending : user.accountBalance,
      adjustedTotal,
    );
  }

  return calculatedDiscount;
};
