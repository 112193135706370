import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

const Context = React.createContext({});
const { Provider } = Context;

export function GlobalConfigProvider({ children, serverData }) {
  const [{ data: globalConfig = {}, loading, ready, error }] = useDeskpassAPI(
    (api) => () => api.global.getConfig(),
    {
      // Set initial state when passed from the server
      ...(serverData.globalConfig && {
        fireOnMount: false,
        initialState: {
          ready: true,
          data: serverData.globalConfig,
        },
      }),
    },
  );

  const context = React.useMemo(
    () => ({
      ready,
      globalConfig,
      loading,
      error,
    }),
    [globalConfig, ready, loading, error],
  );

  return <Provider value={context}>{children}</Provider>;
}

export const useGlobalConfigContext = () => React.useContext(Context);

export default Context;
