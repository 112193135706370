import { createContext, useContext, useEffect, useState } from 'react';

import { consumerToHOC } from '@/lib/hoc';

// This context holds a state that tells wether the user
// is currently viewing the browser tab or not.
const Context = createContext();

export function PageVisibilityProvider({ children }) {
  const [visible, setVisible] = useState(() => true);

  useEffect(() => {
    const handleVisibility = () => {
      setVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibility);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, []);

  return <Context.Provider value={{ visible }}>{children}</Context.Provider>;
}

export const PageVisibilityConsumer = Context.Consumer;
export const usePageVisibilityContext = () => useContext(Context);
export const withPageVisibilityContext = consumerToHOC(
  Context.Consumer,
  'pageVisibilityContext',
);
export default Context;
