/**
 * Lib for configuring OpenReplay session replay functionality.
 */
import { safeWindow as window } from '@/lib/browser';
import config from '@/lib/config';
import { getClientIP } from '@/lib/network/utils';

let sessionTracker = null;
let sessionTrackerReady = null;

(async () => {
  if (config.NODE_ENV === 'test') {
    console.debug('Skipping OpenReplay setup in test environment');

    return;
  }
  if (!config.OPENREPLAY_PROJECT_KEY) {
    console.warn(
      'Not setting up session replay: required config var is not set',
    );

    return;
  }
  // Don't bother setting up OpenReplay in headless browsers (these will come up
  // a lot because of our own Rendertron server)
  if (window.navigator.userAgent.includes('HeadlessChrome')) {
    console.debug('Skipping session replay setup in headless browser');

    return;
  }

  if (window.__OPENREPLAY__) {
    // This is where OR stores the tracker object if it's already been
    // initialized
    // In theory this scenario should only ever come up in dev due to
    // hot reloading
    console.debug('OpenReplay already initialized, skipping setup...');

    sessionTracker = window.__OPENREPLAY__;
  } else if (!sessionTracker) {
    // Set up fresh capture session if it hasn't been established yet
    console.debug('Setting up session capture...');

    // Don't bother setting up OpenReplay in server environments
    if (typeof window === 'undefined') {
      console.debug('Skipping OpenReplay setup in server environment');
      return;
    }

    // Dynamically import the OpenReplay tracker to
    // avoid loading on the server.
    const { default: Tracker } = await import('@openreplay/tracker');

    sessionTracker = new Tracker({
      projectKey: config.OPENREPLAY_PROJECT_KEY,
      ingestPoint: 'https://sr.deskpass.com/ingest',
      __DISABLE_SECURE_MODE:
        config.NODE_ENV === 'development' ||
        ['localhost', 'deskpass-app'].includes(window.location.hostname),
      // @ts-expect-error - The typing is wrong here, defaults for every
      // network option are not necessary
      network: {
        capturePayload: true,
        sanitizer: (data) => {
          // Delete CSP header because it's super long and we don't really
          // need to see it
          delete data.response.headers['content-security-policy'];

          // If request body is a string attempt to parse it as JSON so we
          // can sanitize it as needed
          if (data.request.body) {
            try {
              const requestBody = JSON.parse(data.request.body);

              if (requestBody && typeof requestBody === 'object') {
                // Delete any sensitive data from the request body
                delete requestBody.email;
                delete requestBody.username;
                delete requestBody.password;
                delete requestBody.currentPassword;
                delete requestBody.newPassword;

                data.request.body = JSON.stringify(requestBody);
              }
            } catch (err) {
              // Do nothing
            }
          }

          // Same story with the response body except we don't need to parse
          // it since it will already be an object
          if (data.response.body && typeof data.response.body === 'object') {
            // Delete any sensitive data from the response body
            delete data.response.body.email;
            delete data.response.body.username;
            delete data.response.body.password;
            delete data.response.body.currentPassword;
            delete data.response.body.newPassword;
          }

          return data;
        },
      },
    });

    try {
      sessionTrackerReady = sessionTracker.start({
        metadata: {
          ip: window.CLIENT_IP,
        },
      });

      await sessionTrackerReady;

      console.info('Successfully created new capture session');
    } catch (err) {
      console.error('Error starting session capture:', err);
    }
  }
})();

// Shoot back the tracker object so it can be used elsewhere if needed
export { sessionTracker, sessionTrackerReady };

/**
 * Set user metadata in the OpenReplay session tracker. Will also attempt to
 * retrieve the client's IP address and set that as a metadata field.
 *
 * @param {User} user The user object to set metadata for.
 */
export async function setMetadata(user) {
  // Attempt to retrieve the client's IP address from the API
  let clientIP = null;

  if (!['development', 'test'].includes(config.NODE_ENV)) {
    clientIP = await getClientIP();
  }

  sessionTracker.setUserID(user.email);
  if (clientIP) {
    sessionTracker.setMetadata('ip', clientIP.ip);
  }

  sessionTracker.setMetadata('id', String(user.id));
  sessionTracker.setMetadata(
    'userType',
    user.teamOrganizationId ? 'team' : 'instant',
  );

  console.info('Setting user ID for session capture:', user.email);
}
