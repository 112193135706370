import React from 'react';

import useAPI from '@/hooks/useAPI';
import useEvent from '@/hooks/useEvent';

import { createClient } from './client';
import { DeskpassNetworkClient } from './network-client';

const defaultConfig = {
  abortOnUnmount: true,
};

const useDeskpassAPI = (requestMapper, config = {}) => {
  let { abortOnUnmount } = {
    ...defaultConfig,
    ...config,
  };

  abortOnUnmount = !!abortOnUnmount;

  const abortController = React.useMemo(() => {
    return new AbortController();
  }, []);

  const requestMethodRef = React.useRef({});

  const networkClient = React.useMemo(() => {
    return new DeskpassNetworkClient({
      ...(abortOnUnmount && { signal: abortController.signal }),

      onRequest: (config) => {
        requestMethodRef.current = config.method;
      },
    });
  }, [abortOnUnmount, abortController.signal]);

  // Create an instance that will never change and is dedicated
  // to each request. That way the abort interceptor only
  const apiClient = React.useMemo(() => {
    return createClient(networkClient);
  }, [networkClient]);

  const abortDisposer = useEvent(() => {
    if (requestMethodRef?.current?.toLowerCase?.() === 'get') {
      abortController.abort();
    }
  });

  /*
   * handles request cancellation on component unmount
   */
  React.useEffect(() => {
    if (abortOnUnmount) {
      return () => {
        // Abort request when component unmounts
        // and unregister interceptor
        abortDisposer();
      };
    }
  }, [abortOnUnmount, abortDisposer]);

  return useAPI(apiClient, requestMapper, config);
};

export default useDeskpassAPI;
